import ObjectUtils from "@/utils/object-utils";
import IFile, {IFileDefaultValue} from "@/contracts/i-file";

import {
  SpecPackageImagePreparedLang,
} from "@/lang/enum/spec-package-image-prepared-lang";
import { getTextByLang, i18n } from "@/bootstraps/locale";
import {
  SpecDocumentAttachmentPreparedLang,
} from "@/lang/enum/spec-document-attachment-prepared-lang";

export default class SpecAttachmentEntity {
  public id!:number;
  public specId!:number;
  public type!:SpecAttachmentType;
  public key!:string;
  public attachment: IFile = IFileDefaultValue;

  constructor(init:Partial<SpecAttachmentEntity> = {}) {
    ObjectUtils.assignLiteralFields(this, init);

    if (init.attachment) {
      this.attachment = init.attachment;
    }
  }

  public get isPackageImage():boolean {
    return this.type === SpecAttachmentType.PackageImage;
  }

  public getKeyByLang(lang: string =  i18n.locale): string {
    const texts = this.isPackageImage ? SpecPackageImagePreparedLang : SpecDocumentAttachmentPreparedLang;
    return (this.key in texts) ? getTextByLang(texts[this.key], lang) : this.key;
  }

  public clear() {
    this.attachment.filename = '';
    this.attachment.body = '';
    this.attachment.url = '';
  }
}
export enum SpecAttachmentType {
  PackageImage = 1,
  Document = 2,
}

export const SpecPackageImagePreparedType = {
  PIECE_FRONT : '（ピース）正面',
  PIECE_BACK : '（ピース）裏面',
  PIECE_TOP : '（ピース）天面',
  PIECE_BOTTOM : '（ピース）底面',
  PIECE_RIGHT : '（ピース）右側面',
  PIECE_LEFT : '（ピース）左側面',
  PIECE_LABEL : '（ピース）一括表示ラベル',
  PIECE_INNER : '（ピース）内側',
  OUTERMOST : '最外装',
}

export const SpecManufactureAttachmentPreparedType = {
  HYGIENE_CHECK_SHEET: '衛生監視票',
  MICROBE_TEST_RESULTS: '微生物検査結果',
  HEAVY_METAL_TEST_RESULTS: '重金属試験結果',
  QUALITY_EVIDENCE1: '製造品質添付資料1',
  QUALITY_EVIDENCE2: '製造品質添付資料2',
  CULTIVATION_HISTORY: '栽培履歴',
  RESIDUAL_PESTICIDE_TEST_RESULTS: '残留農薬試験結果',
};

export const SpecDocumentAttachmentPreparedType = {
  ORIGIN_CERTIFICATION: '産地証明書',
  MANUFACTURE_PROCESS: '製造工程',
  ...SpecManufactureAttachmentPreparedType
}

