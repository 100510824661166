
export const SpecDocumentAttachmentPreparedLang = {
  '衛生監視票': {
    ja: "衛生監視票",
    en: "Sanitation Monitoring Sheet",
    cn: "卫生监视票"
  },
  '微生物検査結果': {
    ja: "微生物検査結果",
  },
  '重金属試験結果': {
    ja: "重金属試験結果",
  },
  '製造品質添付資料1': {
    ja: "製造品質添付資料1",
    en: "Document of Manufacturing Quality 1",
    cn: "制造质量附录1"
  },
  '製造品質添付資料2': {
    ja: "製造品質添付資料2",
    en: "Document of Manufacturing Quality 2",
    cn: "制造质量附录2"
  },
  '栽培履歴': {
    ja: "栽培履歴",
  },
  '残留農薬試験結果': {
    ja: "残留農薬試験結果",
  },
  '産地証明書': {
    ja: "産地証明書",
    en: "Certificate of production area",
    cn: "原产地证明书"
  },
  '製造工程': {
    ja: "製造工程",
    en: "Manufacturing Process",
    cn: "制造工程"
  },
};
