
export const PieceFrontKey = '（ピース）正面';

export const SpecPackageImagePreparedLang = {
  [PieceFrontKey]: {
    ja: "（ピース）正面",
    en: "(Piece) Front",
    cn: "（pc）正面"
  },
  '（ピース）裏面': {
    ja: "（ピース）裏面",
    en: "(Piece) Back",
    cn: "（pc）背面"
  },
  '（ピース）天面': {
    ja: "（ピース）天面",
    en: "(Piece) Top",
    cn: "（pc）上面"
  },
  '（ピース）底面': {
    ja: "（ピース）底面",
    en: "(Piece) Bottom",
    cn: "（pc）底面"
  },
  '（ピース）右側面': {
    ja: "（ピース）右側面",
    en: "(Piece) Right side",
    cn: "（pc）右侧面"
  },
  '（ピース）左側面': {
    ja: "（ピース）左側面",
    en: "(Piece) Left side",
    cn: "（pc）左侧面"
  },
  '（ピース）一括表示ラベル': {
    ja: "（ピース）一括表示ラベル",
    en: "(Piece) Food Label",
    cn: "（pc）批量标示标签"
  },
  '（ピース）内側': {
    ja: "（ピース）内側",
    en: "(Piece) Inside",
    cn: "（pc）内侧"
  },
  '最外装': {
    ja: "最外装",
    en: "Outermost package",
    cn: "最外面包装"
  },

};
